form {
  .optional {
    float: right;
    font-size: 0.75rem;
    line-height: 0.9rem;
    color: $text-color-lighter;
    font-weight: 400;
    text-transform: capitalize;
  }
}

.form-group {
  margin-bottom: 1.875rem;

  > label {
    font-weight: $font-weight-bold;
    font-size: .813rem;
    line-height: 0.983rem;
    color: $caption-color;
    display: flex;
    justify-content: space-between;

    &.label-optional {
      display: flex;
      justify-content: space-between;
      align-items: center;
      user-select: none;
      
      > span {
        font-weight: $font-weight-medium;
        color: $caption-color;
      }
    }

    .help-tooltip {
      display: inline-block;
      vertical-align: middle;
      margin-left: .25em;
      cursor: pointer;
      
      .svg-icon {
        height: 1em;
        width: auto;
        line-height: 1;
        vertical-align: baseline;
      }
    }
  }

  .row{
    label {
      display: block;
      font-weight: 600;
      font-size: .875rem;
      line-height: 1rem;
    }
  }

  .invalid-feedback {
    display: block;
    font-size: 0.688rem;
    font-weight: $font-weight-medium;
    line-height: 13px;
    color: $theme-color-red;
    &:first-letter {
      text-transform: uppercase;
    }
  }

  .buttonsSelectList, textarea {
    & + .invalid-feedback {
      margin-top: -0.125rem;
    }
  }

  &.form-group-password {
    .password-wrap {
      position: relative;
      .form-control {
        padding-right: 2.5rem;
        &.is-invalid {
          padding-right: 3.5rem;
        }
        &[type=password] {
          text-overflow: clip;
        }
      }

      .toggle-icon {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 $input-padding-x;
        user-select: none;
        
        svg {
          display: block;
          height: 1.25rem;
          width: auto;
          color: $theme-color-gray;
        }

        &:hover {
          cursor: pointer;

          svg {
            color: $light-blue;
          }
        }
      }
    }
    .forgot-password {
      float: right;
      font-weight: $font-weight-medium;
      font-size: 0.75rem;
    }
  }

  .input-text-wrapper {
    display: flex;
    align-items: center;
    position: relative;

    span {
      font-size: 0.8125rem;
      color: $input-color-light;
      position: absolute;
    }

    &.input-text-right {
      input {
        padding-right: 4em;

        &.was-validated .form-control:invalid, &.form-control.is-invalid {
          background-image: none;
        }
      }
      span {
        right: 1em;
      }
    }

    &.input-text-left {
      input {
        padding-left: 2em;
        text-indent: 0;
      }
      span {
        left: 1em;
      }
    }
  }
  .item-other {
    margin-top: 0.375rem;
  }

  .input-with-toggle-wrap {
    position: relative;

    .form-control {
      padding-right: 2.5rem;
      &.is-invalid {
        padding-right: 3.5rem;
      }
    }

    .toggle-icon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      padding: 0 $input-padding-x;
      box-shadow: none !important;
      
      svg {
        height: 1.25rem;
        width: auto;
        color: $theme-color-gray;
      }

      &:hover {
        svg {
          path {
            fill: $light-blue;
          }
        }
      }
    }
  }
}

.form-wrap {
  margin-bottom: 3rem;
  &.filters-wrap {
    margin-bottom: 1rem;
  }
  .form-container {
    width: 35rem;
    max-width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(0, 102, 164, 0.12);
    border-radius: 0.5rem;
    padding: 2.75rem 3rem;
    .form-title {
      &.additional-margin-bottom {
        margin-bottom: 2.25rem;
      }
    }
  }

  .form-title {
    font-size: 1.375rem;
    font-weight: $font-weight-bold;
    line-height: 1.625rem;
    color: $caption-color;
    margin-bottom: .75rem;
    text-align: center;
  }

  p {
    font-weight: $font-weight-medium;
    font-size: 0.875rem;
    line-height: 1.3rem;
    text-align: center;
    color: #7D99B5;
    margin-bottom: 0;
  }

  .form-control {
    border-radius: 0.375rem;
    padding: 0;
    height: 2.5rem;
    box-shadow: 0 0.125rem 0.125rem rgba(0, 102, 164, 0.08);
    &.is-invalid {
      padding-right: 2rem;
    }
    &.search-input {
      background: url('../../public/svg/search.svg') no-repeat 0.75rem 50%;
      padding-left: 2rem;
    }
    &[aria-expanded="true"] {
      & ~ .autocomplete-dropdown-container {
        position: absolute;
        bottom: -.125rem;
        z-index: 20 !important;
        left: 0;
        right: 0;
        transform: translateY(100%);
        background: #FFF;
        box-shadow: $dropdown-box-shadow;
        padding: 0.35rem 0;
        border-radius: $border-radius;
        border: .0625rem solid $border-color;

        .suggestion-item {
          &, &--active {
            font-size: .875rem;
            padding: 0.35rem 0.6875rem;

            span {
              word-break: break-all;
            }
          }

          &--active {
            cursor: pointer;
            background: $light-background-color
          }
        }
      }
    }
    &:-internal-autofill-selected {
      -webkit-box-shadow: 0 0 0 30px white inset;
    }
  }

  button {
    box-shadow: 0 0.125rem 0.125rem rgba(0, 57, 122, 0.08);
    border-radius: 0.375rem;
    font-weight:  $font-weight-bold;
    font-size: 0.875rem;
    line-height: 1rem;
    padding: 0.75rem 0.75rem;

    &.d-contents {
      display: contents;
    }
  }

  .form-group button {
    height: 2.5rem;
    padding: 0.5rem;
    width: 2.5rem;
  }

  input {
    font-size: 0.813rem;
    line-height: 1.2rem;
    color: $text-color;
    text-indent: 0.75rem;
    border-color: $border-color;
    &.form-control {
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 0.75rem 0 0;
    }

    &::placeholder {
      color: $input-color-light;
      opacity: 1;
    }
    &:focus {
      border-color: $theme-color-blue;
    }
    &[type=radio] {
      box-shadow: none;
    }
    &[type=checkbox] {
      width: 1.125rem;
      height: 1.125rem;
      box-shadow: 0px 2px 4px rgba(0, 53, 128, 0.06);
      border-radius: 0.25rem;
      border: 2px solid $border-color;
      margin-right: 0.625rem;
    }
    &:checked[type="checkbox"] {
      background-image: url('../../public/svg/white-checkmark.svg');
      background-color: $theme-color-blue;
      border-color: $theme-color-blue;
    }
  }

  form {
    margin-top: 2.25rem;
    margin-bottom: 0.75rem;
  }

  .terms-and-privacy {
    font-size: 0.688rem;
    line-height: 1rem;
    max-width: 23rem;
    margin-top: 0.75rem;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    a {
      font-weight: 600;
    }
  }

  .blue-link {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.313rem;
    color: $theme-color-blue;
    padding: 0;
    box-shadow: none;
    &:disabled {
        color: #5f9fc6;
      }
  }
}

.form-control {
  &:disabled,
  &[readonly] {
    background-color: $light-background-color;
    border-color: $light-background-color;
    &:focus {
      color: $text-color-lighter;
      border-color: $light-background-color;
      box-shadow: none;
    }
  }
}

input {
  &:focus-visible {
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  &::placeholder {
    color: $input-color-light;
    opacity: 1;
  }
}



.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: url('../../public/svg/danger.svg');
  background-position: right 13px center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  border-color: $theme-color-red;
  &:focus {
    border-color: $theme-color-red;
    box-shadow: 0 0.125rem 0.125rem rgba(0, 102, 164, 0.08);
  }
}

.password-wrap {
  .was-validated .form-control:invalid, .form-control.is-invalid {
    background-position: right 2.188rem center;
  }
}

textarea {
  border: 1px solid $border-color;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 102, 164, 0.08);
  border-radius: 0.375rem;
  width: 100%;
  height: 6.25rem;
  padding: 0.75rem;
  font-size: .8rem;
  line-height: 1.2rem;
  color: $text-color;
  outline: 0;
  resize: none;
  &::placeholder {
    color: $input-color-light;
    opacity: 1;
  }
  &:focus {
    border-color: $theme-color-blue;
  }
  &.is-invalid {
    border: 1px solid $theme-color-red;
  }
}
.dropzone {
  background: $light-background-color;
  border: 1px dashed $border-color;
  border-radius: 0.375rem;
  height: 7.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  cursor: pointer;

  svg {
    margin-bottom: 0.75rem;
    path {
      fill: $theme-color-blue;
    }
  }
  &:hover {
    border-color: $light-blue;
    svg {
      path {
        fill: $light-blue;
      }
    }
  }

  img {
    max-width: 100%;
    max-height: 6.25rem;
    position: absolute;
  }
  .upload-file {
    &-text {
      color: $text-color-lighter;
      font-weight: 500;
    }
    &-link {
      color: $theme-color-blue;
      font-weight: 600;
    }
  }
}

.form-container, .form-wrap {
  .form-group {
    .validation-rules {
      p {
        color: $text-color-lighter;
        font-weight: $font-weight-medium;
        font-size: 0.688rem;
        line-height: 1rem;
        margin-top: 0.2rem;
        text-align: left;
      }
    }
  }
}

.submit-wrap {
  text-align: right;
  button {
    box-shadow: 0 0.125rem 0.125rem rgba(0, 102, 164, 0.08);
  }

  &.submit-wrap-sticky {
    position: sticky;
    bottom: 0;
    padding: 2rem 0;
    background: rgba(255, 255, 255, 0.9);
    margin: -2rem 0;
  }
}

.modal-content .modal-body {
  .submit-wrap {
    &.submit-wrap-sticky {
      margin-top: -1.25rem;
      margin-bottom: 0;
    }
  }
}

.share-link {
  width: 100%;
  display: flex;
  margin: 0;
  input {
    margin-right: 0.5rem;
    font-size: 0.813rem;
    color: $text-color-lighter;
    background: $light-background-color;
    border-radius: 0.375rem;
    border-color: transparent;
  }
}

@include media-breakpoint-down(sm) {
  .form-wrap {
    .form-container {
      width: 24rem;
      padding-right: 2rem;
      padding-bottom: 2rem;
      padding-left: 2rem;
      .form-title {
        font-size: 1.1rem;
        line-height: 1.3rem;
      }
    }

    p {
      font-size: 0.8rem;
    }

    .form-group {
      margin-bottom: 1.2rem;
    }

    form {
      margin-top: 1.25rem;
      margin-bottom: 0.35rem;
    }
  }
}