@import 'src/styles/variables';

.navbar-header {
  .button-about-us {
    color: $theme-color-blue;
    box-shadow: none !important;
    &:hover,
    &:focus {
      color: $theme-color-blue;
      box-shadow: none !important;
    }
  }
}
