@import "styles/_variables.scss";

.organization-header {
  .organization-logo {
    width: 236px;
    height: 236px;
    object-fit: contain;
    margin-right: 1.5rem;
  }

  .page-title {
    line-height: 1.625rem;
    margin-bottom: .875rem;

    .web-link {
      font-size: .8125rem;
    }
  }

  .page-instructions {
    font-size: .8125rem;
    color: $caption-color;
    line-height: 1.625rem;
    white-space: pre-wrap;
  }
}
