@import "src/styles/variables";

.navbar-header {
  border-bottom: 1px solid #DEE6ED;
  background: $header-bg;
  box-shadow: 0px 2px 6px rgba(0, 102, 164, 0.12);
  z-index: 15;
  min-height: 4rem;

  .navbar-toggler {
    margin-right: .5rem;
    background: #F3F8FB;
    border: none;

    &:focus {
      box-shadow: none;
    }

    svg {
      color: $theme-color-blue;
      width: 1.875rem;
      height: 1.875rem;
    }
  }

  .navbar-brand {
    line-height: 1;
    margin-right: .5rem;
    padding: 0;

    img, svg {
      height: 1.875rem;
      width: auto;
    }
  }

  .container-fluid {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
    justify-content: normal !important;
  }

  .navbar-nav {
    align-items: center;

    .nav-item {
      margin-left: 7.5px;
      &:not(:last-of-type) {
        margin-right: 7.5px;
      }
    }
    @media (max-width: 767px) {
      .nav-item {
        margin: .5rem 0 !important;
      }
    }

    .nav-link {
      &.btn.btn-link {
        color: $theme-color-blue;

        &:hover {
          color: $light-blue
        }
      }
      &.active {
        color: $theme-color-blue;
        background-color: #E0EDF5;
      }
    }
  }

  .collapse.show {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
  }
}
