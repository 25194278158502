.about-us-popup {
  .page-wrap {
    padding-top: 0;
  }
  .page-title {
    text-align: center;
    > p {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }

  .d-none {
    display: flex !important;
  }

  .organization-logo {
    margin-bottom: 1rem;
    margin-right: 0;
  }

  @media (max-width: 767px) {
    .organization-header .organization-logo {
      max-height: 150px;
    }
  }

  .col,
  .col-auto {
    width: 100%;
    justify-content: center;
  }
  @media (min-width: 576px) {
    &.modal-dialog {
      max-width: 48rem;
    }
  }
}
