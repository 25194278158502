@import 'styles/fonts';
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

@import 'styles/variables';

@import "~bootstrap/scss/bootstrap";

@import 'styles/global';
@import 'styles/alerts';
@import 'styles/buttons';
@import 'styles/layouts';
@import 'styles/form';
@import 'styles/tooltips';
@import 'styles/modals';

html, body {
  font-family: 'Inter', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
