@import "styles/_variables.scss";

.app-footer {
  padding: 1.5rem 0;
  color: $caption-color;
  background: $light-background-color;
  bottom: 0;
  width: 100%;
  .container-fluid {
    display: flex;
    justify-content: space-between;
    padding-right: 3rem;
    padding-left: 3rem;
    
    .footer-top-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: .5625rem;

      .logo {
        .svg-icon {
          width: 3.9375rem;
          height: auto;
          
          .fill {
            fill: #99A1A8;
          }
        }
      }
    }

    .copyright, 
    .app-version {
      font-size: .875rem;

      p {
        margin: 0;
        padding: 0;
        font-size: 0.6875rem;
        line-height: 0.8125rem;
        color: $text-color-lighter;
      }
    }

    .website-links {
      display: flex;
      justify-content: space-between;
      p, a {
        margin: 0;
        padding: 0;
        font-size: 0.6875rem;
        line-height: 0.8125rem;
        color: $text-color-lighter;
      }
    }
    .website-link:not(:last-of-type) {
      margin-right: 1.75rem;
    }
  }
}