.page-not-found-align {
  text-align: center;
  align-items: center;
  padding-top: 5%;

  .logo-margin-bottom {
    margin-bottom: 11.375rem;
  }

  .take-me-back-link {
    font-size: 1.125rem;
    font-weight: 600;
  }
}
